/**
 * Specific values for the PROD environment.
 */
 const api_endpoint = 'https://api.newjobapplications.com';

 const environment = {
   env: 'prod',
   number_of_jobs: 20,
   pushnami_id: '5bb39f9e0aa8a80185791889',
   recommend_search_api: `${api_endpoint}/reco/search`,
   recommend_api: `${api_endpoint}/reco/related/query/to/query/`,
   related_api: `${api_endpoint}/reco/search/by/job/`,
   subscriber_api: `${api_endpoint}/subscription`,
   talroo_search_api: `${api_endpoint}/reco/search`,
   search_volume_by_city_api: `${api_endpoint}/usjobs/searchvolumebycity`,
   api_key: 'C6uaMgoHvC3P5xs6uVyuQIZ9jAH47km2AfmkFS0e',
   tenant_id: '4ck54424idu9k40tq8odibj9eh',
   default_api_format: 'json',
   default_api_link: 1,
   default_api_logo: 1,
   j2c_web_api_endpoint: 'https://te59819vs0.execute-api.us-east-1.amazonaws.com/prod/proxies',
   j2c_web_api_bearer_token: 'FEHfiZFOincvDBx5jMKYdump0xdw00OtZHKaicLk9NeWhS2QK67fXO9nbMzUAVEi',
   adSense: {},
   talroo_terms_page: 'https://www.talroo.com/terms/?utm_source=jobsinyourtown.com&utm_campaign=jobs2careers&utm_content=terms',
   talroo_privacy_page: 'https://www.talroo.com/privacy/?utm_source=jobsinyourtown.com&utm_campaign=jobs2careers&utm_content=privacy',
   talroo_ccpa_dns_page: 'https://www.talroo.com/ccpa-dns/?utm_source=jobsinyourtown.com&utm_campaign=jobs2careers&utm_content=dnsmpi',
   talroo_ccpa_privacy_page: 'https://www.talroo.com/ccpa-privacy/?utm_source=jobsinyourtown.com&utm_campaign=jobs2careers&utm_content=ccpa-privacy',
   job_display: {
     use_pop: true,
     ad_cid: 'Y3VzdG9tZXJfaWQ6Mjg0MQ==',
     ad_id: '733bbc61-0942-4d4d-98ff-663dec7b9bf7',
   },
 };
 
 export default environment;
 