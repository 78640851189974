import amazonBackground from './companyImages/amazon/amazon.jpg';
import bbbBackground from './companyImages/bbb/bbb.png';
import dollargeneralBackground
  from './companyImages/dollargeneral/dollargeneral.jpg';
import fedexBackground from './companyImages/fedex/fedex.jpg';
import homedepotBackground from './companyImages/homedepot/homedepot.png';
import mainBackground2 from './companyImages/main/Customer_Service.jpg';
import mainBackground3 from './companyImages/main/Delivery.jpg';
import mainBackground4 from './companyImages/main/Hospitality.jpg';
import mainBackground1 from './companyImages/main/main.jpg';
import mainBackground8 from './companyImages/main/shutterstock_1364416640.jpg';
import mainBackground5 from './companyImages/main/shutterstock_450970201.jpg';
import mainBackground6 from './companyImages/main/shutterstock_577635616.jpg';
import mainBackground7 from './companyImages/main/shutterstock_678279070.jpg';
import mainBackground9 from './companyImages/main/Warehouse.jpg';
import upsBackground from './companyImages/ups/ups.jpg';
import walmartBackground from './companyImages/walmart/walmart.png';

const typography = {
  fontFamily: 'Cabin, Arial',
};

const palette = {
  primary: {
    main: '#72cac3',
  },
  secondary: {
    main: '#4f158b',
  },
  info: {
    main: '#005ee4',
  },
  warning: {
    main: '#e48600',
  },
  error: {
    main: '#e4005e',
  },
  success: {
    main: '#00e486',
  },
};

export const siteName = 'JobsInYourTown';

const themes = {
  main: {
    search_api_info: {},
    header_image: {
      1: mainBackground1,
      2: mainBackground2,
      3: mainBackground3,
      4: mainBackground4,
      5: mainBackground5,
      6: mainBackground6,
      7: mainBackground7,
      8: mainBackground8,
      9: mainBackground9,
    },
    company_title: '',
    emailCompanyWidget: true,
    queries: [
      {
        query: 'Delivery Driver',
      },
      {
        query: 'Warehouse',
      },
      {
        query: 'Call Center',
      },
      {
        query: 'Retail',
      },
      {
        query: 'Other',
      },
    ],
    typography,
    palette,
  },
  ups: {
    search_api_info: {
      id: 5826,
      password: 'zdzsXWuYB4pKoZ6S',
    },
    header_image: {
      1: upsBackground,
    },
    company_title: 'UPS',
    queries: [
      {
        query: 'Warehouse Associate',
      },
      {
        query: 'Package Handler',
      },
      {
        query: 'Mailroom Clerk',
      },
      {
        query: 'Forklift Operator',
      },
      {
        query: 'Delivery Driver',
      },
      {
        query: 'Retail Associate',
      },
      {
        query: 'Cashier',
      },
      {
        query: 'Other',
      },
    ],
    typography,
    palette,
  },
  fedex: {
    search_api_info: {
      id: 5634,
      password: 'qpETQ4W0Ee2ugHIu',
    },
    header_image: {
      1: fedexBackground,
    },
    company_title: 'FedEx',
    queries: [
      {
        query: 'Warehouse Associate',
      },
      {
        query: 'Package Handler',
      },
      {
        query: 'Mailroom Clerk',
      },
      {
        query: 'Forklift Operator',
      },
      {
        query: 'Delivery Driver',
      },
      {
        query: 'Retail Associate',
      },
      {
        query: 'Cashier',
      },
      {
        query: 'Other',
      },
    ],
    typography,
    palette,
  },
  amazon: {
    search_api_info: {
      id: 5822,
      password: '4rH1EQwR0T9lLnLk',
    },
    header_image: {
      1: amazonBackground,
    },
    company_title: 'Amazon',
    queries: [
      {
        query: 'Warehouse Associate',
      },
      {
        query: 'Package Handler',
      },
      {
        query: 'Mailroom Clerk',
      },
      {
        query: 'Forklift Operator',
      },
      {
        query: 'Delivery Driver',
      },
      {
        query: 'Retail Associate',
      },
      {
        query: 'Cashier',
      },
      {
        query: 'Other',
      },
    ],
    typography,
    palette,
  },
  bbb: {
    search_api_info: {
      id: 5823,
      password: '4G7HE0gUTblgwrYg',
    },
    header_image: {
      1: bbbBackground,
    },
    company_title: 'Bed Bath & Beyond',
    queries: [
      {
        query: 'Stocker',
      },
      {
        query: 'Warehouse',
      },
      {
        query: 'Store Associate',
      },
      {
        query: 'Cashier',
      },
      {
        query: 'Shopper',
      },
      {
        query: 'Customer Service',
      },
      {
        query: 'Supervisor',
      },
      {
        query: 'Other',
      },
    ],
    typography,
    palette,
  },
  dollargeneral: {
    search_api_info: {
      id: 5824,
      password: 'KpsUCBmgGDqhkykd',
    },
    header_image: {
      1: dollargeneralBackground,
    },
    company_title: 'Dollar General',
    queries: [
      {
        query: 'Stocker',
      },
      {
        query: 'Warehouse',
      },
      {
        query: 'Store Associate',
      },
      {
        query: 'Cashier',
      },
      {
        query: 'Shopper',
      },
      {
        query: 'Customer Service',
      },
      {
        query: 'Supervisor',
      },
      {
        query: 'Other',
      },
    ],
    typography,
    palette,
  },
  homedepot: {
    search_api_info: {
      id: 5825,
      password: '2fSs7wWvInh6t3db',
    },
    header_image: {
      1: homedepotBackground,
    },
    company_title: 'Home Depot',
    queries: [
      {
        query: 'Stocker',
      },
      {
        query: 'Warehouse',
      },
      {
        query: 'Store Associate',
      },
      {
        query: 'Cashier',
      },
      {
        query: 'Shopper',
      },
      {
        query: 'Customer Service',
      },
      {
        query: 'Supervisor',
      },
      {
        query: 'Other',
      },
    ],
    typography,
    palette,
  },
  walmart: {
    search_api_info: {
      id: 5827,
      password: 'OqcLA6YnJIi8Hhk2',
    },
    header_image: {
      1: walmartBackground,
    },
    company_title: 'Walmart',
    queries: [
      {
        query: 'Stocker',
      },
      {
        query: 'Warehouse',
      },
      {
        query: 'Store Associate',
      },
      {
        query: 'Cashier',
      },
      {
        query: 'Shopper',
      },
      {
        query: 'Customer Service',
      },
      {
        query: 'Supervisor',
      },
      {
        query: 'Other',
      },
    ],
    typography,
    palette,
  },
};

export default themes;
